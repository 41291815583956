import Vue from "vue";
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css"; // Base style, required.
import App from "./App.vue";
import router from "./router";
import store from "./store";

import WeShowPlugin from "./plugins/WeShowPlugin";
import { config } from "./utils";

Vue.config.productionTip = false;
Vue.use(WeShowPlugin);
Vue.use(DropdownMenu);

const _vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

if (config.debug) {
  window._vm = _vm;
}
