<template>
  <div class="we-form-field">
    <validation-provider :rules="rules" :name="name" v-slot="{errors}">
      <textarea
       :id="id" 
       :value="value" 
       @input="updateValue" 
       type="text" 
       class="we-input" 
       :placeholder="placeholder"
       :class="{'error': errors[0]}"
       :style="styles"
      />
      <span class="error">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: 'we-textarea',
  props: {
    id: { type: String },
    placeholder: { type: String },
    value: { type: [String, Number ]},
    required: { type: Boolean },
    rules: { type: [String, Array, Object], default: '' },
    name: { type: String, default: '' },
    styles: { type: String },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
  }
}
</script>