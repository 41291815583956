import { userService } from "./user.service";
import router from '../router/index'

const state = {
  user: {},
  status: { loggedIn: false },
  me: {},
};

const actions = {
  login({commit}, {username, password}) {
    commit('loginRequest', { username });
    return userService.login(username, password)
      .then(
        user => {
          commit('loginSuccess', user);
          router.push('/');
        },
        error => {
          commit('loginFailure', error);
          return Promise.reject(error);
        }
      );
  },
  logout({ commit }) {
    userService.logout();
    commit('logout');
    router.push('/login');
  },
  getMe({ commit }) {
    userService.getMe().then(data => {
      commit('getMeSuccess', data);
    })
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = {};
  },
  logout(state) {
    state.status = {};
    state.user = {};
  },
  getMeSuccess(state, account) {
    state.me = account;
  },
};

const getters = {};

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};