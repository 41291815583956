<template>
  <div>
    <md-table class="we-table">
      <md-table-toolbar v-if="$slots.toolbar" style="margin: 15px 0 20px 0;">
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <md-table-row v-if="tableHead.length > 0">
        <md-table-head
          v-for="(head, key) in tableHead" 
          :key="`head-${key}`" 
          :class="{ 'alignment-right': head.type === 'number', 'alignment-center': head.align === 'center', 'alternative-1': alternative }"
        >
          {{ head.label }}
        </md-table-head>
      </md-table-row>
      <template v-if="loading">
        <md-table-row>
          <md-table-cell :colspan="tableHead.length" class="alignment-center">
            <md-progress-spinner
              class="md-accent" 
              :md-stroke="3" 
              md-mode="indeterminate" 
              style="padding: 80px 0px;"
            />
          </md-table-cell>
        </md-table-row>
      </template>
      <template v-else>
        <md-table-row v-if="tableData.length === 0">
          <md-table-cell :colspan="tableHead.length" class="alignment-center">
            データがありません。
          </md-table-cell>
        </md-table-row>
        <md-table-row v-for="(item, key) in currentTableData" :key="`row-${key}`" :class="alternative===true ? `alternative-${key % 2}` : ''">
          <md-table-cell
            v-for="(head, key) in tableHead" 
            :key="`cell-${key}`" 
            :md-numeric="head.type === 'number'" 
            :class="{ 'alignment-center': head.align === 'center' }"
          >
            <md-avatar v-if="head.type === 'avatar'" class="md-small">
              <img v-if="getValueByKey(head, item)" :src="getValueByKey(head, item)" alt="Avatar">
              <md-icon v-else style="font-size: 30px !important;">account_circle</md-icon>
            </md-avatar>
            <template v-else-if="head.actions">
              <template v-for="(act, key) in head.actions">
                <md-menu v-if="act.name === 'others'" :key="`action-${key}`" md-align-trigger>
                  <we-button size="small" :outline="act.outline" md-menu-trigger>その他</we-button>
                  <md-menu-content class="we-menu">
                    <md-menu-item v-for="(act_sub, key1) in act.actions(item)" :key="key1" @click="handleAction(act_sub, item)">
                      {{ getActionText(act_sub.name) }}
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
                <we-button
                  v-else
                  :key="`action-${key}`"
                  size="small"
                  :outline="act.outline"
                  @click="handleAction(act, item)"
                >
                  {{ getActionText(act.name) }}
                </we-button>
              </template>
            </template>
            <span v-else>{{ head.choice ? getChoiceText(getValueByKey(head, item), head.choice) : getValueByKey(head, item) }}</span>
          </md-table-cell>
        </md-table-row>
      </template>
    </md-table>
    <div v-if="server">
      <div class="table-pagination" v-if="loading === false">
        <span class="prev-page" @click="prevPage">&lt;前頁</span>
        <span class="next-page" @click="nextPage">次頁&gt;</span>
        <span>
          <select v-model.number="pageSize" @change="onPageSizeChange">
            <option value="5">5 / ページ</option>
            <option value="10">10 / ページ</option>
            <option value="25">25 / ページ</option>
            <option value="50">50 / ページ</option>
          </select>
        </span>
      </div>
    </div>
    <div v-else>
      <div class="table-pagination" v-if="pageCount > 0 && loading === false">
        <span>合計 {{pageCount}} ページ</span>
        <span class="prev-page" :class="{ 'disable': currentPage <= 1 }" @click="prevPage">&lt;</span>
        <span class="page-no" :class="{ 'current': currentPage === pageNo }" v-for="(pageNo, key) in pageNoList" :key="`page-${key}`" @click="pageChange(pageNo)">{{ pageNo }}</span>
        <span class="next-page" :class="{ 'disable': currentPage >= pageCount }" @click="nextPage">&gt;</span>
        <span>
          <select v-model.number="pageSize" @change="onPageSizeChange">
            <option value="5">5 / ページ</option>
            <option value="10">10 / ページ</option>
            <option value="25">25 / ページ</option>
            <option value="50">50 / ページ</option>
          </select>
        </span>
      </div>
    </div>
    <md-dialog-confirm
      :md-active.sync="deleteActive"
      md-title="確認してください"
      :md-content="`${deleteText ? '下記データを' : '' }削除します、よろしいですか？<br/>${deleteText}`"
      md-confirm-text="確定"
      md-cancel-text="取消"
      @md-cancel="onDeleteCancel"
      @md-confirm="onDeleteConfirm"
    />
  </div>
</template>
<script>
import { common, config } from '../utils';
export default {
  name: 'we-table',
  props: {
    tableHead: { type: Array, default: () => [] },
    tableData: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] },
    sort: { type: String, default: null },
    sortOrder: { type: String, default: null },
    alternative: { type: Boolean, default: true },
    loading: { type: Boolean, default: true },
    server: { type: Boolean, default: true },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: config.rowsPerPage,
      deleteActive: false,
      deleteText: null,
      deleteCallback: null,
    }
  },
  computed: {
    pageCount() {
      if (this.server) {
        return 99999;
      } else {
        const count = parseInt(this.tableData.length / this.pageSize);
        if (this.tableData.length % this.pageSize === 0) {
          return count;
        } else {
          return count + 1;
        }
      }
    },
    pageNoList() {
      if (this.pageCount > 0) {
        return [...Array(this.pageCount).keys()].map(i => i + 1);
      } else {
        return [];
      }
    },
    currentTableData() {
      if (this.server) {
        return this.tableData;
      } else {
        const start = this.pageSize * (this.currentPage - 1);
        return this.tableData.slice(start, start + this.pageSize);
      }
    },
  },
  methods: {
    getValueByKey(column, json) {
      const { name, comma } = column;
      const index = name.indexOf('.');
      let value = null;
      if (index > 0) {
        value = json[name.substring(0, index)][name.substring(index + 1, name.length)];
      } else {
        value = json[name];
      }
      if (comma === true) {
        return common.toNumComma(value);
      } else {
        return value;
      }
    },
    getChoiceText(value, choice) {
      return common.getChoiceText(value, choice);
    },
    getActionText(name) {
      if (name === 'update') {
        return "編集";
      } else if (name === 'delete') {
        return "削除";
      } else {
        return name;
      }
    },
    pageChange(page) {
      this.currentPage = page;
    },
    onPageSizeChange() {
      if (this.currentPage > this.pageCount) {
        this.currentPage = this.pageCount;
      }
      if (this.server) {
        this.currentPage = 1;
        this.$emit('paging', {currentPage: this.currentPage, pageSize: this.pageSize});
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        if (this.server) {
          this.$emit('paging', {currentPage: this.currentPage, pageSize: this.pageSize});
        }
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount) {
        if (!this.server) {
          this.currentPage += 1;
        }
        if (this.server && this.tableData && this.tableData.length === this.pageSize) {
          this.currentPage += 1;
          this.$emit('paging', {currentPage: this.currentPage, pageSize: this.pageSize});
        }
      }
    },
    handleAction(act, item) {
      if (act.name === 'delete') {
        this.deleteActive = true;
        this.deleteCallback = () => act.callback(item);
        if (act.verbose) {
          this.deleteText = common.formatStr(act.verbose, item)
        }
      } else if (act.callback) {
        act.callback(item);
      }
    },
    onDeleteConfirm() {
      if (this.deleteCallback) {
        this.deleteCallback();
      }
    },
    onDeleteCancel() {
      this.deleteText = null;
      this.deleteCallback = null;
    },
  }
}
</script>
