<template>
  <md-card class="we-card">
    <md-card-header>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <span class="mark"></span>
          <span style="margin-left: 8px">{{title}}</span>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100" style="text-align: right; padding-right: 16px;">
          <slot name="header-action"></slot>
        </div>
      </div>
      <slot></slot>
    </md-card-content>
    <md-card-actions v-if="$slots.footer">
      <div style="width: 100%;">
        <slot name="footer"></slot>
      </div>
    </md-card-actions>
  </md-card>
</template>
<script>
export default {
  name: "we-card",
  props: {
    title: { type: String }
  }
}
</script>
<style scoped>
.md-card {
  box-shadow: none;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 15px;
}
.md-card .mark {
  display: inline-block;
  width: 8px;
  height: 28px;
  line-height: 20px;
  border-radius: 8px 8px 8px 8px;
  background-color: rgba(224, 81, 147, 100);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 100);
  margin-bottom: -9px;
}
.md-card .md-card-actions {
  padding-bottom: 30px;
}
.md-card >>> .md-card-actions .md-button+.md-button {
  margin-left: 32px !important;
}
</style>