export default {
  SUCCESS: {
    ARTIST_SAVED: "アーティストが保存されました。",
    EVENT_SAVED: "イベントが保存されました。",
    DELETED: "アーティストが削除されました。",
    PAUSE: "一時停止しました。",
    RESUME: "再開しました。",
    DISMISS: "ライブを強制終了しました。",
    BAN: "ファンを退室させました。",
    BLACKLIST_ADDED: "ブラックリストに追加しました。",
    BANNED_REASON: "BANの理由を付けました",
    SUCCESS: "成功しました",
  },
  ERROR: {
    RQUIRE_CHOOSE_REASON: "BANの理由をご選択ください。",
    RQUIRE_BANNED_REASON: "BANの理由をご記入ください。",
    UNEXCEPTED: "予期できないエラーが発生しました。",
    PAUSE_IN_FIVE: "対話中5秒以内は一時停止できません。",
    NOT_FOUND: "リソースが存在しません。",
    NO_CURRUSER: "参加者がいない",
  },
  CONFIRM: {
    BAN: "このファンを強制退室します、よろしいですか？",
    BAN_WITH_NAME: "メンバー「%s」をBANしますか？",
    PAUSE: "一時停止します、よろしいですか？",
    RESUME: "一時停止から再開します、よろしいですか？",
    DISMISS: "強制終了します、よろしいですか？",
    REMOVE_FROM_BLACKLIST: "ブラックリストから削除します、よろしいですか？",
  },
};
