export const hostApi = process.env.VUE_APP_API_URL;

export default {
  api: {
    authenticate: {
      login: hostApi + "/login",
      refresh: hostApi + "/refresh",
    },
    account: {
      me: hostApi + "/account/me",
    },
    artist: {
      list: "/livers",
      create: "/livers",
      detail: "/livers/%s/",
    },
    event: {
      list: "/events",
      listBefoe: "/events/before",
      listAfter: "/events/after",
      listLiving: "/events/living",
      create: "/events",
      detail: "/events/%s/",
      delay: "/events/%s/delay",
      ticket: {
        list: "/events/%s/tickets",
        create: "/events/%s/tickets",
        detail: "/events/%s/tickets/%s",
      },
      service: {
        list: "/events/%s/services",
        create: "/events/%s/services",
        detail: "/events/%s/services/%s",
        template: {
          create: "/events/%s/services/%s/templates",
          detail: "/events/%s/services/%s/templates/%s",
        },
      },
    },
    organization: {
      list: "/organizations",
      detail: "/organizations/%s/",
    },
    living: {
      list: "/events/living",
      sections: "/events/living/sections",
      detail: "/events/living/%s/",
      paused: "/events/living/%s/paused",
      pause: "/events/living/%s/pause",
      resume: "/events/living/%s/resume",
      ban: "/events/living/%s/ban",
      bannedReason: "/events/living/%s/banned-reason",
      dismiss: "/events/living/%s/dismiss",
      waitingList: "/events/living/%s/waiting",
      serverTime: "/sync/time",
      addMonitorLog: "/events/living/%s/addMonitorLog",
    },
    fan: {
      list: "/fans",
      detail: "/fans/%s",
      events: "/fans/events",
      blacklist: {
        list: "/fans/blacklist",
        detail: "/fans/blacklist/%s",
      },
      banlist: "/fans/banlist",
    },
  },
  debug: true,
  rowsPerPage: 10,
};
