<template>
  <div>
    <md-list>
      <!-- <md-list-item to="/dashboard" exact>
        <span class="md-list-item-text">ダッシュボート</span>
      </md-list-item>
      <md-list-item to="/artists" exact>
        <span class="md-list-item-text">アーティスト管理</span>
      </md-list-item>
      <md-list-item to="/events" exact>
        <span class="md-list-item-text">イベント管理</span>
      </md-list-item> -->
      <md-list-item to="/monitor" exact>
        <span class="md-list-item-text">監視</span>
      </md-list-item>
      <md-list-item to="/banlist" exact>
        <span class="md-list-item-text">BANリスト</span>
      </md-list-item>
      <!-- <md-list-item to="/turnover" exact>
        <span class="md-list-item-text">売上・販売状況</span>
      </md-list-item>
      <md-list-item to="/fans" exact>
        <span class="md-list-item-text">ファン会員管理</span>
      </md-list-item>
      <md-list-item to="/notices" exact>
        <span class="md-list-item-text">お知らせ</span>
      </md-list-item>
      <md-list-item to="/setting" exact>
        <span class="md-list-item-text">通常設定</span>
      </md-list-item> -->
    </md-list>
    <div class="side-footer">
      <ul class="md-list md-theme-default">
        <!-- <li class="md-list-item">
          <div class="md-list-item-content">
            <a href="javascript:void(0)">よくある質問
              <md-icon>open_in_new</md-icon>
            </a>
          </div>
        </li> -->
        <li class="md-list-item">
          <div class="md-list-item-content">
            <a href="https://drive.google.com/file/d/1MZ1ZZqTmdqSmfTkSEqQtd6D82Ig6x7h4/view?usp=sharing" target="_blank">お役立ち資料
              <md-icon>open_in_new</md-icon>
            </a>
          </div>
        </li>
        <!-- <li class="md-list-item">
          <div class="md-list-item-content">
            <a href="javascript:void(0)">お知らせ
              <md-icon>open_in_new</md-icon>
            </a>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
  .md-drawer .md-list-item-text {
    display: block;
    text-align: center;
  }
  .side-footer {
    margin-top: 250px;
  }
  .side-footer .md-list-item-content {
    min-height: 30px;
    margin-left: 35px;
  }
  .side-footer >>> a {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  .side-footer >>> a .md-icon {
    font-size: 14px !important;
  }
  .side-footer >>> a:hover {
    text-decoration: none;
  }
  .md-list .router-link-active {
    background-color: rgba(224, 81, 147, .25);
    border: 1px solid rgba(255, 255, 255, 100);
    border-left: 4px solid rgba(224, 81, 147, 100);
  }
  .md-list .router-link-active .md-list-item-text {
    color: rgba(224, 81, 147, 100) !important;
  }
</style>