import {
  Card,
  PlusCard, 
  StatsCard,
  MonitorCard,
  BaseButton, 
  BaseInput, 
  BaseSelect, 
  BaseTable,
  BaseImage,
  BaseTextarea,
  BaseDate,
} from '@/components';
import { extend, localize } from 'vee-validate';
import lang from "vee-validate/dist/locale/ja.json";
import { required, min, max, email, confirmed } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);

localize("ja", lang);


const GlobalComponents = {
  install(Vue) {
    Vue.component(Card.name, Card);
    Vue.component(PlusCard.name, PlusCard);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(MonitorCard.name, MonitorCard);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(BaseImage.name, BaseImage);
    Vue.component(BaseTextarea.name, BaseTextarea);
    Vue.component(BaseDate.name, BaseDate);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
  }
};

export default GlobalComponents;
