<template>
  <div class="we-form-field">
    <validation-provider :rules="rules" :name="name" v-slot="{errors}">
      <date-picker
        :id="id" 
        :value="value" 
        @input="updateValue" 
        :class="{'error': errors[0]}"
        :format="format"
        :type="type"
      />
      <span class="error" v-if="error">{{ error }}</span>
      <span class="error" v-else>{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: 'we-date-picker',
  props: {
    id: { type: String },
    type: { type: String, default: 'datetime' },
    value: { type: [String, Number, Date]},
    rules: { type: [String, Array, Object], default: '' },
    name: { type: String, default: '' },
    error: { type: String, default: null },
    format: { type: String, default: 'YYYY-MM-DD HH:mm' },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  }
}
</script>