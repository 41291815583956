import axios from "axios";
import authHeader, { token } from "./authHeader";
import config, { hostApi } from "./config";
import router from '../router/index'
import { constant } from '.'

const ajax = axios.create({
  baseURL: hostApi,
});

ajax.interceptors.request.use(config => {
  Object.assign(config.headers, authHeader());
  return config;
});

ajax.interceptors.response.use(
  res => {
    return res.data;
  },
  async error => {
    if (error.response) {
      if (error.config.url === config.api.authenticate.refresh) {
        router.push('/login');
        return Promise.reject(error);
      } else if (error.response.status === 401) {
        await fetch(config.api.authenticate.refresh, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ jwt: token() })
        }).then(response => {
          if (!response.ok) {
            router.push('/login');
            return Promise.reject(response);
          } else {
            return response.text()
          }
        }).then(text => {
          const data = JSON.parse(text);
          if (data.jwt) {
            localStorage.setItem('token', JSON.stringify(data.jwt));
            return ajax.request(error.config);
          } else {
            router.push('/login');
            return Promise.reject(text);
          }
        }).catch((response) => {
          router.push('/login');
          return Promise.reject(response);
        });
      } else if (error.response.status === 500) {
        alert(constant.ERROR.UNEXCEPTED)
      }
      return Promise.reject(Object.assign({ status: error.response.status }, error.response.data));
    } else {
      Promise.reject(error);
    }
  }
);

export default ajax;
