<template>
  <div class="we-form-field">
    <validation-provider :rules="rules" :name="name" v-slot="{errors}">
      <input
        :id="id" 
        :value="value" 
        @input="updateValue" 
        :type="type" 
        class="we-input" 
        :placeholder="placeholder"
        :readonly="readonly"
        :class="{'error': errors[0]}"
        :autocomplete="autocomplete"
      />
      <span class="error" v-if="error">{{ error }}</span>
      <span class="error" v-else>{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: 'we-input',
  props: {
    id: { type: String },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    value: { type: [String, Number ]},
    required: { type: Boolean },
    rules: { type: [String, Array, Object], default: '' },
    name: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    autocomplete: { type: String },
    error: { type: String, default: null },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
  }
}
</script>