<template>
  <md-card class="we-monitor-card">
    <md-ripple>
      <md-card-media>
        <router-link :to="`/monitor/${livingId}/?section=${section}&page=${page}`">
          <img :src="imageCover" alt="People">
        </router-link>
      </md-card-media>
    </md-ripple>
    <md-card-header>
      <div class="md-subhead">{{title}}</div>
    </md-card-header>
    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-15">
          <md-avatar>
            <img :src="avatar" alt="Avatar">
          </md-avatar>
        </div>
        <div class="md-layout-item" style="text-align: center">
          <div>
            {{name}}
          </div>
          <div style="font-size: 11px">
            {{period}}
          </div>
        </div>
        <!-- <div class="md-layout-item md-size-20">
          <div style="white-space: nowrap; margin-top: 10px;">
            <md-icon style="color: rgba(224, 81, 147, 100);">person</md-icon>
            <span style="font-size: 11px;">484</span>
          </div>
        </div> -->
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: 'we-monitor-card',
  props: {
    livingId: { type: Number },
    title: { type: String, default: null },
    avatar: { type: String, default: null },
    name: { type: String, default: null },
    imageCover: { type: String, default: null },
    period: { type: String, default: null },
    section: { type: String, default: '' },
    page: { type: Number, default: 1 },
  },
  data() {
    return {
      
    }
  },
  methods: {
    toMonitorDetail() {
      console.log(1)
    },
  }
}
</script>
