<template>
  <div class="we-form-field">
    <validation-provider :rules="rules" :name="name" v-slot="{errors}">
      <select :id="id" :value="value" @input="updateValue" class="we-select">
        <option :value="null">{{emptyText}}</option>
        <option v-for="(item, key) in options" :key="key" :selected="item.value === value" :value="item.value">{{ item.text }}</option>
      </select>
      <span class="error" v-if="error">{{ error }}</span>
      <span class="error" v-else>{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: 'we-select',
  props: {
    id: { type: String },
    value: { type: [String, Number], default: null},
    options: { type: Array, default: () => [] },
    emptyText: { type: String, default: null },
    required: { type: Boolean },
    rules: { type: [String, Array, Object], default: '' },
    name: { type: String, default: '' },
    error: { type: String, default: null },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
  }
}
</script>