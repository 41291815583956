import { vsprintf } from 'sprintf-js';

export default {

  /**
   * JSONかどうかを判定する関数
   * @param {Object} arg 
   */
   isJSON: function(arg) {
    return typeof arg === 'object';
  },

  clone: function(data) {
    return JSON.parse(JSON.stringify(data));
  },

  /**
   * 文字列をフォーマットする
   * @param {String} format 
   *
   * 使用方法：utils.format('This is argument: %s', arg1);
   */
   formatStr: function(format) {
    if (!format) {
      return null;
    } else if (arguments && arguments.length === 2 && this.isJSON(arguments[1])) {
      return vsprintf(format, arguments[1]);
    } else {
      var i = 0,
        j = 0,
        r = "",
        next = function(args){
          j += 1; i += 1;
          return args[j] !== void 0 ? args[j] : "";
        };

      for(i=0; i<format.length; i++){
        if(format.charCodeAt(i) === 37){
          switch(format.charCodeAt(i+1)){
            case 115: r += next(arguments); break;
            case 100: r += Number(next(arguments)); break;
            default: r += format[i]; break;
          }
        } else {
          r += format[i];
        }
      }
      return r;
    }
  },

  /**
   * 日付をフォーマットする
   * @param {Date} date 
   * @param {String} format 
  */
  formatDate: function (date, format) {
    if (!format) {
        format = 'YYYY-MM-DD hh:mm:ss.SSS';
    }
    if (!date) {
      return null;
    } else if (typeof date === "string") {
        date = new Date(date);
    }
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
        var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
        var length = format.match(/S/g).length;
        for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  },

  dateDiffSeconds: function(dateFrom, dateTo) {
    const d1 = new Date(dateFrom);
    const d2 = new Date(dateTo);
    const diff = d2.getTime() - d1.getTime();
    return diff / 1000;
  },
  
  /**
   * 選択肢から該当値のテキストを取得する
   * @param {*} value 値
   * @param {*} choice 選択肢
   * @returns 
   */ 
  getChoiceText: function(value, choice) {
    const option = choice.find(i => i.value === value);
    return option ? option.text : value;
  },

  loading: function() {
    const musk = document.createElement('div');
    musk.className = "musk";
    document.body.appendChild(musk);
    const icon = document.createElement('img');
    icon.setAttribute('src', '/loading.gif')
    musk.appendChild(icon);
  },

  loaded: function() {
    const loading = document.querySelector("div.musk");
    if (loading) {
      loading.remove();
    }
  },

  /**
   * 数字をカンマ区切りで表示
   * @param {Integer} num 数字
   */
     toNumComma: function(num) {
      if (num === null || num === undefined) {
        return '';
      } else {
        const int_comma = (num + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return int_comma;
      }
    },
  
}