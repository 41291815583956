<template>
  <md-content class="stats-card" :class="`bg-${color}-stats`">
    <div class="stats-title">{{ title }}</div>
    <div class="stats-content">{{ content }}</div>
  </md-content>
</template>
<script>
export default {
  name: 'we-stats-card',
  props: {
    color: { type: String, default: 'blue' },
    title: { type: String },
    content: { type: String },
  }
};
</script>
<style scoped>
.stats-card {
  min-width: 150px;
  max-width: 250px;
  min-height: 80px;
  padding: 15px 20px 30px;
  margin: 10px 5px;
  opacity: 0.87;
  border-radius: 5px;
  font-family: Roboto;
}
.bg-blue-stats {
  background-color: rgba(134, 147, 243, 100) !important;
  box-shadow: 0px 2px 6px 0px rgba(149, 161, 245, 100);
}
.bg-pink-stats {
  background-color: rgba(255, 153, 153, 100) !important;
  box-shadow: 0px 2px 6px 0px rgba(254, 166, 167, 100);
}
.bg-water-stats {
  background-color: rgba(120, 171, 217, 100) !important;
  box-shadow: 0px 2px 6px 0px rgba(137, 181, 222, 100);
}
.bg-purple-stats {
  background-color: rgba(164, 123, 208, 100) !important;
  box-shadow: 0px 2px 6px 0px rgba(175, 140, 214, 100);
}
.bg-green-stats {
  background-color: rgba(0, 204, 153, 100) !important;
  box-shadow: 0px 2px 6px 0px rgba(33, 209, 166, 100);
}
.stats-title ,
.stats-content {
  color: white;
}
.stats-title {
  font-size: 13px;
}
.stats-content {
  margin-top: 8px;
  font-size: 14px;
}
</style>
