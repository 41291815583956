<template>
  <div class="we-image">
    <validation-provider :rules="rules" :name="name" ref="provider" v-slot="{errors}">
      <md-avatar class="avatar" :class="`avatar-${type}`" :style="squareSize">
        <img v-if="value" :src="value" alt="Avatar">
        <img v-else src="@/assets/img/avatar.png" />
        <a @click="showDialog = true">変更</a>
      </md-avatar>
      <md-dialog :md-active.sync="showDialog">
        <md-dialog-title>プロフィール写真変更</md-dialog-title>
        <div style="padding: 10px 50px;">
          <vue-croppie
            ref="croppieRef"
            :enableOrientation="false"
            :enableResize="false"
            :viewport="{ width: aspectRatioWidth, height: aspectRatioHeight, type: type }"
            :boundary="{ width: 350, height: 350 }"
          >
          </vue-croppie>
          <input type="file" @change="croppie"/>
        </div>
        <md-dialog-actions>
          <md-button class="md-secondray" @click="showDialog = false">取消</md-button>
          <md-button class="md-primary" @click="handleOk">確定</md-button>
        </md-dialog-actions>
      </md-dialog>
      <span class="error" v-if="error">{{ error }}</span>
      <span class="error" v-else>{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: 'we-image',
  props: {
    value: { type: String },
    type: { type: String, default: 'circle' },
    required: { type: Boolean },
    rules: { type: [String, Array, Object], default: '' },
    name: { type: String, default: '' },
    error: { type: String, default: null },
    aspectRatioX: { type: Number, default: 1 },
    aspectRatioY: { type: Number, default: 1 },
  },
  watch: {
    value() {
      this.$refs.provider.syncValue(this.value);
    }
  },
  data() {
    return {
      showDialog: false,
      cropped: false,
    }
  },
  computed: {
    aspectRatioWidth() {
      if (this.aspectRatioX > this.aspectRatioY) {
        return 300;
      } else {
        return parseInt(this.aspectRatioX * 300 / this.aspectRatioY);
      }
    },
    aspectRatioHeight() {
      if (this.aspectRatioX > this.aspectRatioY) {
        return parseInt(this.aspectRatioY * 300 / this.aspectRatioX);
      } else {
        return 300;
      }
    },
    squareSize() {
      if (this.type === 'square') {
        return ({
          'width': 'inherit',
          'height': 'inherit',
          'max-width': `${parseInt(this.aspectRatioX * 120 / this.aspectRatioY)}px`,
          'max-height': '120px',
        });
      } else {
        return ({});
      }
    },
  },
  methods: {
    croppie (e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.cropped = false;
        return;
      }

      this.cropped = true;
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options = {
        type: 'base64',
        size: 'original',
        format: 'png',
      };
      this.$refs.croppieRef.result(options, output => {
        this.$refs.provider.validate(output);
        this.$emit('input', output);
      });
    },
    handleOk() {
      if (this.cropped) {
        this.crop();
        this.showDialog = false
      }
    },
  }
}
</script>