<template>
  <md-button
    :class="`md-raised we-button size-${size} outline-${outline}`"
    @click="handleClick"
    :to="to"
  >
    <slot>
    </slot>
  </md-button>
</template>
<script>
export default {
  name: 'we-button',
  props: {
    size: { type: String, default: 'default' },
    outline: { type: String },
    to: { type: Object }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    }
  }
}
</script>
<style scoped>
.we-button {
  font-size: 13px;
  padding: 0 10px;
  border-radius: 30px;
  color: rgba(255, 255, 255, 100) !important;
  background-color: rgba(224, 81, 147, 100) !important;
}
.we-button.size-small {
  height: 24px;
  margin-top: 1px;
  margin-bottom: 0;
}
.md-button+.md-button {
    margin-left: 8px;
}
.we-button.outline-red {
  background-color: rgba(224, 81, 147, 0) !important;
  border: 1px solid rgba(224, 81, 147, 100) !important;
  color: rgba(224, 81, 147, 100) !important;
}
.we-button.outline-blue {
  background-color: rgba(121, 160, 241, 0) !important;
  border: 1px solid rgba(121, 160, 241, 100) !important;
  color: rgba(121, 160, 241, 100) !important;
}
</style>