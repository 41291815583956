<template>
  <div class="we-plus-card">
    <a :href="hasClickListener ? 'javascript:void(0)' : null" @click="handleClick">
      <div class="we-plus-card-title">
        {{ title }}
      </div>
      <div class="we-plus-card-icon">
        <md-icon class="md-size-4x">add</md-icon>
      </div>
      <div class="we-plus-card-desc">
        {{ description }}
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: 'we-plus-card',
  props: {
    title: { type: String, required: true },
    description: { type: String },
  },
  data() {
    return {
      
    }
  },
  computed: {
    hasClickListener() {
      return this.$listeners && this.$listeners.click
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
}
</script>
