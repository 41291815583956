export const token = () => JSON.parse(localStorage.getItem('token'));

const authHeader = () => {
  // return authorization header with jwt token
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    return {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      "Content-Type": "application/json; charset=utf-8",
    };
  } else {
    return {
      'Accept': 'application/json',
      "Content-Type": "application/json; charset=utf-8",
    };
  }
}

export default authHeader;
