import VueRouter from 'vue-router'
import MainLayout from '../views/layout/MainLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainLayout,
    redirect: 'monitor',
    children: [
      // {
      //   name: 'dashboard',
      //   path: '/dashboard',
      //   component: () => import('../views/dashboard/Dashboard.vue'),
      // },
      // {
      //   name: 'artists',
      //   path: '/artists',
      //   component: () => import('../views/artist/ArtistList.vue'),
      // },
      // {
      //   name: 'artists-add',
      //   path: '/artists/add',
      //   component: () => import('../views/artist/ArtistForm.vue'),
      // },
      // {
      //   name: 'artists-edit',
      //   path: '/artists/:id/',
      //   component: () => import('../views/artist/ArtistForm.vue'),
      // },
      // {
      //   name: 'events',
      //   path: '/events',
      //   component: () => import('../views/event/EventList.vue'),
      // },
      {
        name: 'events-add',
        path: '/events/add',
        component: () => import('../views/event/EventStepper.vue'),
      },
      {
        name: 'events-edit',
        path: '/events/:id/',
        component: () => import('../views/event/EventStepper.vue'),
      },
      {
        name: 'monitor',
        path: '/monitor',
        component: () => import('../views/monitor/MonitorList.vue'),
      },
      {
        name: 'monitor-detail',
        path: '/monitor/:id/',
        component: () => import('../views/monitor/MonitorDetail.vue'),
      },
      {
        name: 'banlist',
        path: '/banlist',
        component: () => import('../views/fan/BanList.vue'),
      },
      // {
      //   name: 'turnover-dashboard',
      //   path: '/turnover',
      //   component: () => import('../views/turnover/Dashboard.vue'),
      // },
      // {
      //   name: 'fans',
      //   path: '/fans',
      //   component: () => import('../views/fan/FanList.vue'),
      // },
      // {
      //   name: 'fan-blacklist',
      //   path: '/fans/blacklist',
      //   component: () => import('../views/fan/BlackList.vue'),
      // },
      // {
      //   name: 'notices',
      //   path: '/notices',
      //   component: () => import('../views/notice/NoticeList.vue'),
      // },
      // {
      //   name: 'setting',
      //   path: '/setting',
      //   component: () => import('../views/setting/Setting.vue'),
      // },
    ]
  },
  {
    path: '/login',
    component: () => import('../views/account/Login.vue'),
    meta: { requiresLogin: false },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresLogin === false) && !localStorage.getItem('token')) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
});

export default router
