import Vue from 'vue'
import Vuex from 'vuex'
import { user } from './user.module'
import { meeting } from './meeting.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account: user,
    meeting,
  }
});
