<template>
  <div class="page-container md-layout-row">
    <md-app>
      <md-app-toolbar md-elevation="0" class="md-transparent" style="margin-left: 5px; background-color: white;">
        <md-button class="md-icon-button" @click="showNavigation = true">
          <md-icon>menu</md-icon>
        </md-button>
        <span class="md-title" style="flex: 1"></span>
        <span>
          {{me.nickName}}
        </span>
        <md-button class="md-icon-button" style="margin-right: 10px;" @click="signout">
          <md-icon>logout</md-icon>
        </md-button>
      </md-app-toolbar>

      <md-app-drawer md-swipeable :md-active.sync="showNavigation">
        <md-toolbar class="md-transparent" md-elevation="0">
          <md-card-media>
            <img src="@/assets/img/logo.png" />
          </md-card-media>
        </md-toolbar>
        <SideMenu />
      </md-app-drawer>

      <md-app-content style="border: none; margin: 8px 5px; padding: 0; background-color: transparent;">
        <we-transition>
          <router-view></router-view>
        </we-transition>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SideMenu from './SideMenu';
import { CollapseTransition } from 'vue2-transitions';

export default {
  components: {
    SideMenu,
    'we-transition': CollapseTransition,
  },
  data() {
    return {
      showNavigation: false,
    }
  },
  computed: {
    ...mapState('account', ['me']),
  },
  created() {
    this.getMe();
  },
  methods: {
    ...mapActions('account', ['logout', 'getMe']),
    signout() {
      this.logout();
    },
  }
}
</script>

<style scoped>
  .md-app {
    min-height: 350px;
    border: 1px solid rgba(#000, .12);
    background-color: rgba(246, 249, 255, 100) !important;
  }
  .md-app >>> .md-app-container {
    /* overflow: visible; */
    /* margin-left: 230px; */
    overflow: inherit;
  }

  .md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
    position: fixed !important;
  }

  .md-app .md-card-media {
    margin: 40px auto;
  }
  .md-app .md-card-media img {
    width: 130px;
  }
</style>