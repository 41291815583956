const state = {
  im: null,
  liveIsStop: false,
  msg: null,
  meetingUser: null,
};

const actions = {
  setIm({commit}, data) {
    commit('setImSuccess', data);
  },
  setLiveIsStop({ commit }, data) {
    commit('setLiveIsStopSuccess', data);
  },
  setMsg({ commit }, data) {
    commit('setMsgSuccess', data);
  },
  setMeetingUser({ commit }, data) {
    commit('setMeetingUserSuccess', data);
  },
};

const mutations = {
  setImSuccess(state, data) {
    state.im = data;
  },
  setLiveIsStopSuccess(state, data) {
    state.liveIsStop = data;
  },
  setMsgSuccess(state, data) {
    state.msg = data;
  },
  setMeetingUserSuccess(state, data) {
    state.meetingUser = data;
  },
}

const getters = {
  im(state) {
    return state.im;
  },
  liveIsStop(state) {
    return state.liveIsStop;
  },
  meetingUser(state) {
    return state.meetingUser;
  },
};

export const meeting = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};