import VueRouter from "vue-router";
import VueMaterial from 'vue-material';
import VueCroppie from 'vue-croppie';
import DatePicker from 'vue2-datepicker';
import Toasted from 'vue-toasted';
import GlobalComponents from './GlobalComponents';

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import '@/assets/scss/we.scss';
import 'croppie/croppie.css';
import 'vue2-datepicker/index.css';

export default {
  install(Vue) {
    Vue.use(VueRouter);
    Vue.use(VueMaterial);
    Vue.use(VueCroppie);
    Vue.use(DatePicker);
    Vue.use(Toasted, {
      duration: 5000,
    });
    Vue.use(GlobalComponents);
  }
};
