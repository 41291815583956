import { ajax, config } from '../utils/index'

export const userService = {
  login,
  getMe,
  logout,
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        username,
        password
      }
    )
  };

  return fetch(config.api.authenticate.login, requestOptions)
    .then(handleResponse)
    .then(data => {
      if (data.jwt) {
        localStorage.setItem('token', JSON.stringify(data.jwt));
      }
      return data;
    })
}

function getMe() {
  return ajax.get(config.api.account.me);
}

function logout() {
  localStorage.removeItem('token')
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // if (response.status === 401) {
      //   // auto logout if 401 response returned from api
      //   logout();
      //   //location.reload(true);
      // }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}